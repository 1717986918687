import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Header from '../../Common/Headers/Header';
import SearchFilter from '../../Common/Search/SearchFilter';
import NoMatch from '../../Common/Search/NoMatch';
import Community from './Community';

import '../../Common/stylesheets/global.scss';

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  }
});

class CommunityList extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      filteredCommunities: this.props.communities,
      noMatch: false,
      classes: classes
    };
  }

  handleSearch(data) {
    let filteredCommunities = this.props.communities.filter(community => {
      return (community.name || '').toLowerCase().indexOf(data.toLowerCase()) !== -1;
    });
    this.setState({ filteredCommunities: filteredCommunities });
    this.setState({
      noMatch: filteredCommunities.length > 0 ? false : true
    });
  }

  render() {
    return (
      <div className={this.state.classes.root}>
        <Header headerText="Select Community" user={this.props.user} />
        <SearchFilter placeholderText="Search" filterText={this.handleSearch} />
        <List>
          {this.state.filteredCommunities.map((community, index) => (
            <Community key={index} community={community} />
          ))}
        </List>
        {this.state.noMatch ? <NoMatch matchText="No communities match" /> : ''}
      </div>
    );
  }
}

export default withStyles(styles)(CommunityList);

import React, { Component } from 'react';
import NoCodeVacant from './NoCodeVacant';
import NoCodeOccupied from './NoCodeOccupied';
import CodeOccupied from './CodeOccupied';
import CodeVacant from './CodeVacant';
import '../../Common/stylesheets/global.scss';
import '../accesscode.css';

class AccessCode extends Component {
  render() {
    //conditionals
    let code = this.props.code;
    let residentsExist = this.props.unit.residents.length > 0;
    let codeNotExists =
      Object.entries(code).length === 0 && code.constructor === Object;

    let state = <NoCodeOccupied {...this.props} />;
    if (!codeNotExists && residentsExist) {
      state = <CodeOccupied {...this.props} />;
    } else if (!codeNotExists && !residentsExist) {
      state = <CodeVacant {...this.props} />;
    } else if (codeNotExists && !residentsExist) {
      state = <NoCodeVacant {...this.props} />;
    }
    return state;
  }
}

export default AccessCode;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import withStyles from '@material-ui/core/styles/withStyles';
import '../../Common/stylesheets/global.scss';
import '../unit.css';

const styles = theme => ({
  overrideRootBlue: {
    float: 'right',
    marginTop: '-35px',
    marginRight: '16px',
    position: 'unset',
    color: '#4ad4d4'
  }
});

class Unit extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.navigateToUnit = this.navigateToUnit.bind(this);
    this.classes = classes;
  }
  navigateToUnit(e) {
    e.preventDefault();
    return this.props.history.push(
      '/community/' +
        this.props.unit.communityId +
        '/unit/' +
        this.props.unit.uid +
        '/access'
    );
  }
  render() {
    let names = '';
    this.props.unit.residents.map((resident, index) => {
      if (resident.firstName) {
        let name =
          resident.firstName.charAt(0).toUpperCase() + '. ' + resident.lastName;
        names += name + ', ';
      } else {
        names += resident.email + ', ';
      }
      return '';
    });
    if (names === '') {
      names = 'Vacant';
    } else {
      names = names.slice(0, -2);
    }
    //defaults
    let code = this.props.unit.code;
    let no_residents = this.props.unit.residents.length < 1;
    let no_code =
      Object.entries(code).length === 0 && code.constructor === Object;

    let avatarColor = 'dwelo-avatar';
    let keyIcon = (
      <ListItemSecondaryAction
        classes={{ root: this.classes.overrideRootBlue }}
      >
        <VpnKeyIcon />
      </ListItemSecondaryAction>
    );
    let icon = <PersonIcon />;

    //check if there are residents/a code
    if (no_residents && no_code) {
      icon = <MeetingRoomIcon />;
      avatarColor = 'gray-avatar';
      keyIcon = '';
    } else if (!no_residents && no_code) {
      avatarColor = '';
      keyIcon = '';
    } else if (no_residents && !no_code) {
      icon = <MeetingRoomIcon />;
    }

    return (
      <ListItem onClick={this.navigateToUnit} button={true}>
        <ListItemAvatar>
          <Avatar className={avatarColor}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={this.props.unit.unit} secondary={names} />
        {keyIcon}
      </ListItem>
    );
  }
}

export default withRouter(withStyles(styles)(Unit));

import axios from 'axios';

const client = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_HOST,
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function(config) {
    const token = localStorage.getItem('token');
    config.headers.authorization = token ? `Token ${token}` : '';
    return config;
  });

  instance.interceptors.response.use(
    response => {
      // intercept the global error
      return response;
    },
    function(error) {
      if (error.response.status === 401) {
        // if the error is 401 and hasent already been retried
        localStorage.clear();
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return instance;
};

export default client();

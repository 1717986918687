import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DomainIcon from '@material-ui/icons/Domain';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthService from '../utils/AuthService';
import { withRouter } from 'react-router-dom';

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  userName: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#868686'
  },
  grayText: {
    color: '#868686'
  }
};
const Auth = new AuthService();

class Menu extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.switchCommunity = this.switchCommunity.bind(this);
    this.state = {
      left: this.props.left
    };
  }

  handleLogout(e) {
    Auth.logout().then(res => {
      return this.props.history.push('/login');
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ left: nextProps.left });
  }

  switchCommunity(e) {
    e.preventDefault();
    if (this.props.location.pathname === '/') {
      window.location.reload(true);
    } else {
      return this.props.history.push('/');
    }
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
    if (!open) {
      this.props.close();
    }
  };

  render() {
    const { classes } = this.props;
    let name = '';
    if (this.props.user.firstName) {
      name = this.props.user.firstName + ' ' + this.props.user.lastName;
    }
    let switchCommunity = (
      <ListItem button onClick={this.switchCommunity}>
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.grayText }}
          primary="Switch community"
        />
      </ListItem>
    );
    if (localStorage.getItem('multiple') === 'false') {
      switchCommunity = '';
    }

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem>
            <ListItemText
              classes={{
                primary: classes.userName,
                secondary: classes.grayText
              }}
              primary={name}
              secondary={this.props.user.email}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          {switchCommunity}
          <ListItem button onClick={this.handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.grayText }}
              primary="Sign out"
            />
          </ListItem>
        </List>
      </div>
    );

    return (
      <div>
        <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Menu));

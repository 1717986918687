export default function viewportHack() {
  window.onresize = function() {
    document.body.style.height = window.innerHeight + 'px';
    let innerWidth = window.innerWidth;
    let element = document.getElementById('central-column');
    if (innerWidth >= 570) {
      element.style.width = '570px';
    } else if (innerWidth >= 350) {
      element.style.width = innerWidth + 'px';
    } else {
      element.style.width = '350px';
    }
  };
  window.onresize();
}

import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Header from '../../Common/Headers/Header';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import withStyles from '@material-ui/core/styles/withStyles';

import theme from '../../Common/Themes/login_theme';
import '../../Common/stylesheets/global.scss';
import '../accesscode.css';
import * as routes from '../accesscode_apiRoutes';
import client from '../../Common/utils/client';

const styles = theme => ({
  grayedBackground: {
    backgroundColor: '#DDDDDD'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: 20,
    marginRight: 20
  },
  card: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  cardHeader: {
    width: '100%',
    backgroundColor: 'rgb(131, 131, 131)',
    padding: '16px',
    height: '60px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    fontSize: '18px'
  },
  avatar: {
    backgroundColor: 'rgb(131, 131, 131)',
    borderRadius: '0%',
    width: '50px',
    height: '50px'
  },
  meetingRoom: {
    width: '50px',
    height: '50px'
  },
  title: {
    fontSize: '30px',
    color: 'white'
  },
  close: {
    color: 'white',
    width: '30px',
    marginTop: '-6px',
    height: '30px'
  },
  textField: {
    width: '100%'
  },
  textLabel: {
    fontSize: '18px',
    marginTop: '16px'
  },
  textLabelRed: {
    fontSize: '18px',
    marginTop: '16px',
    color: 'red',
    textAlign: 'center',
    fontWeight: '500'
  },
  noWrap: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%'
  }
});

class AccessCode extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCode = this.getCode.bind(this);
    this.classes = classes;
    this.state = {
      error: ''
    };
  }
  cancel(e) {
    return this.props.history.push('/community/' + this.props.unit.communityId);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSelectChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  setErrorMessage(message) {
    this.setState({
      error: message
    });
  }
  getCode(e) {
    e.preventDefault();
    client
      .post(routes.ACCESSCODE(this.props.unit.gatewayId), { duration: null })
      .then(res => {
        window.location.reload();
      });
  }

  render() {
    let name = this.props.user.email;
    if (this.props.user.firstName) {
      name = this.props.user.firstName + ' ' + this.props.user.lastName;
    }
    let content = (
      <CardContent>
        <Typography component="p" className={this.classes.textLabel}>
          Assign a door code to
        </Typography>
        <TextField
          disabled
          defaultValue={name}
          className={this.classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={this.classes.submit}
          onClick={this.getCode}
        >
          GET DOOR CODE
        </Button>
      </CardContent>
    );
    if (!this.props.pins_exist) {
      content = (
        <CardContent>
          <Typography component="p" className={this.classes.textLabelRed}>
            There are no more door codes available for this unit. Please contact
            Dwelo support at{' '}
            <a
              href="https://dwelo.com/support"
              target="_blank"
              rel="noopener noreferrer"
            >
              dwelo.com/support
            </a>
          </Typography>
        </CardContent>
      );
    }
    return (
      <div className={this.classes.grayedBackground + ' full-height'}>
        <Header
          headerText={this.props.unit.communityName}
          user={this.props.user}
        />
        <main className={this.classes.main}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Card className={this.classes.card}>
              <CardHeader
                className={this.classes.cardHeader}
                classes={{
                  title: this.classes.title,
                  content: this.classes.noWrap
                }}
                avatar={
                  <Avatar className={this.classes.avatar}>
                    <MeetingRoomIcon className={this.classes.meetingRoom} />
                  </Avatar>
                }
                action={
                  <IconButton onClick={this.cancel}>
                    <CloseIcon className={this.classes.close} />
                  </IconButton>
                }
                title={this.props.unit.unit}
              />
              {content}
            </Card>
          </MuiThemeProvider>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(AccessCode);

const pageVisited = () => {
  return window.analytics && window.analytics.page();
};
const identifyUser = () => {
  let user = localStorage.getItem('user');
  if (user !== null){
    let {uid, email} = JSON.parse(user);
    return window.analytics && window.analytics.identify(uid, { email });
  }
};
export { pageVisited, identifyUser };

import React, { Component } from 'react';
import Header from '../../Common/Headers/Header';
import SearchFilter from '../../Common/Search/SearchFilter';
import NoMatch from '../../Common/Search/NoMatch';
import Unit from './Unit';
import List from '@material-ui/core/List';

import '../../Common/stylesheets/global.scss';
import '../unit.css';

class UnitList extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      filteredUnits: this.props.units,
      noMatch: false,
      hasUnits: this.props.units.length > 0 ? true : false
    };
  }

  handleSearch(data) {
    let filteredUnits = this.props.units.filter(unit => {
      return (unit.unit || '').toLowerCase().indexOf(data.toLowerCase()) !== -1;
    });
    this.setState({ filteredUnits: filteredUnits });
    this.setState({
      noMatch: filteredUnits.length > 0 ? false : true
    });
  }

  render() {
    let hasUnits = (
      <div>
        <SearchFilter placeholderText="Search" filterText={this.handleSearch} />
        <List>
          {this.state.filteredUnits.map((unit, index) => (
            <Unit key={index} unit={unit} />
          ))}
        </List>
        {this.state.noMatch ? <NoMatch matchText="No units match" /> : ''}
      </div>
    );

    let noUnits = <NoMatch matchText="No units" />;

    return (
      <div className="full-height">
        <div className="unit-container">
          <Header
            headerText={this.props.community.name}
            user={this.props.user}
          />
          {this.state.hasUnits ? hasUnits : noUnits}
        </div>
      </div>
    );
  }
}

export default UnitList;

import React, { Component } from 'react';
import AuthService from '../utils/AuthService';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './Menu';

import theme from '../Themes/header_theme';
import '../stylesheets/global.scss';

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10
  }
};
const Auth = new AuthService();

class Header extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.classes = classes;
    this.state = {
      left: false
    };
  }

  handleLogout(e) {
    Auth.logout().then(res => {
      return this.props.history.push('/login');
    });
  }

  closeDrawer = () => {
    this.setState({
      left: false
    });
  };

  toggleDrawer(e) {
    this.setState({
      left: true
    });
  }
  render() {
    return (
      <div className={this.classes.root}>
        <MuiThemeProvider theme={theme}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={this.classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={this.classes.grow}
              >
                {this.props.headerText}
              </Typography>
            </Toolbar>
          </AppBar>
        </MuiThemeProvider>
        <Menu
          left={this.state.left}
          user={this.props.user}
          close={this.closeDrawer}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Header);

import React, { Component } from 'react';
import '../stylesheets/global.scss';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    search: {
      position: 'relative',
      backgroundColor: fade('#DDDDDD', 0.15),
      marginLeft: 0,
      width: '100%',
      height: '60px',
      padding: '10px 20px 10px 20px'
    },
    searchIcon: {
      width: '52px',
      height: '40px',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      height: '20px',
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: '50px',
      width: '100%'
    }
  });

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.updateSearch = this.updateSearch.bind(this);
    const { classes } = props;
    this.classes = classes;
  }
  updateSearch(e) {
    this.props.filterText(e.target.value);
  }

  render() {
    return (
      <div>
        <div className={this.classes.grow} />
        <div className={this.classes.search}>
          <div className={this.classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={this.props.placeholderText}
            classes={{
              root: this.classes.inputRoot,
              input: this.classes.inputInput
            }}
            onChange={this.updateSearch}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SearchFilter);

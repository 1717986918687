import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Header from '../../Common/Headers/Header';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import theme from '../../Common/Themes/login_theme';
import '../../Common/stylesheets/global.scss';
import '../accesscode.css';

const styles = theme => ({
  grayedBackground: {
    backgroundColor: '#DDDDDD'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: 20,
    marginRight: 20
  },
  card: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  cardHeader: {
    width: '100%',
    backgroundColor: '#4ad4d4',
    padding: '16px',
    height: '60px'
  },
  avatar: {
    backgroundColor: '#4ad4d4',
    borderRadius: '0%',
    width: '50px',
    height: '50px'
  },
  meetingRoom: {
    width: '50px',
    height: '50px'
  },
  title: {
    fontSize: '30px',
    color: 'white'
  },
  close: {
    color: 'white',
    width: '30px',
    marginTop: '-6px',
    height: '30px'
  },
  textField: {
    width: '100%'
  },
  textLabel: {
    fontSize: '18px',
    marginTop: '16px'
  },
  textLabelNoMargin: {
    fontSize: '18px'
  },
  doorCode: {
    fontSize: '100px',
    fontWeight: '400'
  },
  noWrap: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%'
  }
});

class NoCodeOccupied extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.classes = classes;
    this.state = {
      error: '',
      duration: 24
    };
  }
  cancel(e) {
    return this.props.history.push('/community/' + this.props.unit.communityId);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSelectChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  setErrorMessage(message) {
    this.setState({
      error: message
    });
  }
  handleFormSubmit(e) {
    e.preventDefault();
    this.setErrorMessage('');
  }

  render() {
    return (
      <div className={this.classes.grayedBackground + ' full-height'}>
        <Header
          headerText={this.props.unit.communityName}
          user={this.props.user}
        />
        <main className={this.classes.main}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Card className={this.classes.card}>
              <CardHeader
                className={this.classes.cardHeader}
                classes={{
                  title: this.classes.title,
                  content: this.classes.noWrap
                }}
                avatar={
                  <Avatar className={this.classes.avatar}>
                    <MeetingRoomIcon className={this.classes.meetingRoom} />
                  </Avatar>
                }
                action={
                  <IconButton onClick={this.cancel}>
                    <CloseIcon className={this.classes.close} />
                  </IconButton>
                }
                title={this.props.unit.unit}
              />
              <CardContent>
                <Typography component="p" className={this.classes.textLabel}>
                  Door code
                </Typography>
                <Typography component="p" className={this.classes.doorCode}>
                  {this.props.code.value}
                </Typography>
                <Typography
                  component="p"
                  className={this.classes.textLabelNoMargin}
                >
                  Expires when
                </Typography>
                <Typography
                  component="p"
                  className={this.classes.textLabelNoMargin}
                >
                  a resident moves into this unit
                </Typography>
              </CardContent>
            </Card>
          </MuiThemeProvider>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(NoCodeOccupied);

import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';

import theme from '../Common/Themes/login_theme';
import logo from '../Common/images/logo.svg';
import '../Common/stylesheets/global.scss';
import './login.css';
import AuthService from '../Common/utils/AuthService';
import { identifyUser } from '../Common/utils/segment';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    width: '80px',
    height: '80px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();
    this.state = {
      error: '',
      classes: classes
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  setErrorMessage(message) {
    this.setState({
      error: message
    });
  }
  handleFormSubmit(e) {
    e.preventDefault();
    this.setErrorMessage('');

    this.Auth.login(this.state.email, this.state.password)
      .then(res => {
        identifyUser();
        this.props.history.replace('/');
      })
      .catch(err => {
        this.setErrorMessage('Invalid Username or Password');
      });
  }
  componentDidMount() {
    if (this.Auth.loggedIn()) this.props.history.replace('/');
  }

  render() {
    return (
      <main className={this.state.classes.main}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Paper className={this.state.classes.paper}>
            <Avatar className={this.state.classes.avatar}>
              <img src={logo} alt="logo" className="login-logo" />
            </Avatar>
            <form className={this.state.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange}
                />
              </FormControl>
              <div className="error-message" name="error">
                {this.state.error}
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.state.classes.submit + ' dwelo-blue'}
                onClick={this.handleFormSubmit}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </MuiThemeProvider>
      </main>
    );
  }
}

export default withStyles(styles)(SignIn);

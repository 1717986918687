import client from './client';
import * as routes from '../../Login/login_apiRoutes';
import * as Sentry from '@sentry/browser';

export default class AuthService {
  login(email, password) {
    return client
      .post(routes.LOGIN, {
        email: email,
        password: password,
        applicationId: process.env.REACT_APP_APPLICATION_ID
      })
      .then(this._checkStatus)
      .then(res => {
        this.setToken(res.data.token);
        this.setUser(res.data.user);
        return Promise.resolve(res);
      });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token;
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  removeUser() {
    localStorage.removeItem('user');
  }

  logout() {
    return client
      .post(routes.LOGOUT, {})
      .then(this._checkStatus)
      .then(res => {
        this.removeToken();
        this.removeUser();
        return Promise.resolve(res);
      })
      .catch(error => {
        this.removeToken();
        this.removeUser();
        return Promise.resolve(error);
      });
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      Sentry.captureException(error);
      throw error;
    }
  }
}
